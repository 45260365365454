<template>
  <th-wrapper>
    <div class="filters-wrapper flex items-center justify-between mb-6">
      <!-- Search -->
      <el-input
        v-model="search"
        :placeholder="$t('common.interactions.search.placeholder')"
        class="max-w-sm"
        prefix-icon="Search"
      />

      <!-- Add -->
      <el-button type="primary" icon="Plus" @click="handleNew">
        {{ $t('common.interactions.buttons.add') }}
      </el-button>
    </div>

    <!-- Table -->
    <el-table :data="filteredData" :loading="loading">
      <el-table-column
        v-for="(item, index) in headers"
        :key="index"
        :label="item.label"
        :prop="item.field"
        :min-width="item.minWidth"
        :formatter="item.formatter"
        show-overflow-tooltip
      />
      <el-table-column
        :label="$t('pages.settings.api_users.all.headers.token')"
        prop="api_key"
        show-overflow-tooltip
        min-width="140"
      >
        <template #default="scope">
          <div class="flex flex-row items-center">
            <span class="truncate">{{ scope.row.api_key }}</span>
            <i
              :tabindex="0"
              class="el-icon-copy-document outline-none cursor-pointer focus:text-blue-600 hover:text-blue-600"
              :title="$t('common.interactions.copy.clipboard')"
              @click="handleTokenCopy(scope.row.api_key)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="right" min-width="190">
        <template #default="scope">
          <!-- Delete -->
          <el-button
            plain
            icon="Delete"
            class="el-button--text-icon"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <token-form ref="token-create" @refresh="fetch" />
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import TokenForm from './components/token-form'
import { copyToClipboard } from '@/utils/dom'

const newForm = () => ({
  user: {
    id: null,
    email: null
  },
  name: null,
  description: null,
  role: 'serviceaccount'
})

export default {
  components: {
    TokenForm
  },
  data() {
    return {
      form: newForm(),
      headers: [
        {
          field: 'created_at',
          label: this.$t('pages.settings.api_users.all.headers.created_at'),
          minWidth: 120,
          fallback: '–',
          formatter: (row, column) => {
            const date = safeGet(row, 'created_at.iso')
            return date
              ? new Date(date).toLocaleDateString(this.locale, {
                  timeZone: this.timeZone
                })
              : '–'
          }
        },
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          minWidth: 120,
          formatter: (row) => row.name || '–'
        },
        {
          field: 'description',
          label: this.$t('common.headers.description.title'),
          minWidth: 120,
          formatter: (row) => row.description || '–'
        }
      ],
      tableData: [],
      search: '',
      loading: false
    }
  },
  computed: {
    timeZone() {
      return (
        safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.default_timezone'
        ) || 'Europe/Berlin'
      )
    },
    configurationId() {
      if (
        this.$store.state.Config.clientAccountConfiguration &&
        this.$store.state.Config.clientAccountConfiguration.id
      ) {
        return this.$store.state.Config.clientAccountConfiguration.id
      }

      return null
    },
    filteredData() {
      if (!this.search) return this.tableData
      const search = RegExp(this.search.toLowerCase(), 'i')
      return this.tableData.filter(
        (data) =>
          (data.name || '').match(search) ||
          (data.description || '').match(search) ||
          (safeGet(data, 'user.name') || '').match(search) ||
          (safeGet(data, 'user.description') || '').match(search) ||
          (safeGet(data, 'api_key') || '').match(search)
      )
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const apiUsersRoles = ['serviceaccount', 'frenchise', 'frenchisee']
      const filterInAPIUsers = (user) => apiUsersRoles.includes(user.role)
      try {
        const { data } = await th
          .users(this.configurationId)
          .getAll({ query: { deleted: false } })
        this.tableData = data.filter(filterInAPIUsers)
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: err.message
        })
      } finally {
        this.loading = false
      }
    },
    handleNew() {
      this.$thModal.show('token-create')
    },
    async handleDelete(index, row) {
      try {
        const user = row.name || row.id
        await this.$confirm(
          this.$t('common.delete_confirm.api_users.text', { user }),
          this.$t('common.delete_confirm.title'),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel'),
            type: 'warning'
          }
        )
      } catch (err) {
        // no-op
        return
      }

      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.user.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.user.singular')
      })
      try {
        const inst = th.users(this.configurationId)
        await inst.delete(row.id)
        this.loading = false
        this.fetch()
        this.$message({
          message: successMessage,
          type: 'success'
        })
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async handleTokenCopy(token) {
      try {
        await copyToClipboard(token)
        this.$message({
          type: 'success',
          message: this.$t('pages.settings.api_users.copy_token.message')
        })
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message
        })
      }
    }
  }
}
</script>

<style scoped>
.filters-wrapper :deep(.el-input input),
.filters-wrapper :deep(.el-button) {
  height: 40px;
}
</style>
