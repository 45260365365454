<template>
  <th-modal
    name="token-create"
    width="500px"
    :title="$t('pages.settings.api_users.form.title')"
    @close="close"
  >
    <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
      <!-- Name -->
      <el-form-item prop="name" :label="$t('common.titles.name')">
        <el-input id="name" v-model="form.name" />
      </el-form-item>

      <!-- Description -->
      <el-form-item
        prop="description"
        :label="$t('pages.settings.api_users.form.description.title')"
        class="mb-0"
      >
        <el-input id="description" v-model="form.description" type="textarea" />
      </el-form-item>
    </el-form>

    <!-- Footer -->
    <template #footer>
      <!-- Close -->
      <el-button @click="close">
        {{ $t('common.interactions.buttons.close') }}
      </el-button>

      <!-- Create -->
      <el-button type="primary" @click="submitForm">
        {{ $t('common.interactions.buttons.create') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'

const newForm = () => ({
  user: {
    id: null,
    email: null
  },
  name: null,
  description: null,
  role: 'serviceaccount'
})

export default {
  data() {
    return {
      form: newForm(),
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.settings.api_users.form.rules.name.required'
            ),
            trigger: 'blur'
          }
        ],
        description: [
          {
            min: 3,
            max: 32,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 32
            }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    configurationId() {
      return safeGet(this.$store.state.Config.clientAccountConfiguration, 'id')
    }
  },
  methods: {
    close() {
      this.form = newForm()
      this.$thModal.hide('token-create')
    },

    async submitForm() {
      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }

      this.createUser()
    },

    async createUser() {
      const successMessage = this.$t('common.success.action.create.single', {
        resource: this.$t('common.resource.user.singular')
      })
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.user.singular')
      })
      try {
        this.loading = true
        const { data = {} } = await th
          .users(this.configurationId)
          .create(this.form)

        this.$message({
          message: successMessage,
          type: 'success'
        })

        if (data.id) {
          await this.createAPIToken(data.id)
          this.$emit('refresh')
          this.close()
        }
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },

    async createAPIToken(userId) {
      const errorMessage = this.$t(
        'pages.settings.users.edit.form.errors.post.code_XXX.content'
      )
      try {
        await th.users(this.configurationId).createToken(userId)
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },

    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
